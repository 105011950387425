import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"
import "../stylesheets/application.scss"
import "../javascripts/common.js"
import "@fortawesome/fontawesome-free/js/all"
import "cocoon"

Rails.start()
ActiveStorage.start()
